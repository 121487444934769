import React, { FC, useRef } from 'react';
import { ProductsId } from '@webc/meeseeks-ui-sdk';
import { useTranslations } from 'app/hooks/useTranslations';
import { useSelector } from 'react-redux';
import { selectedProductsSelector } from 'app/store/selectors';
import { useScrollToElement } from 'app/hooks/useScrollToElement';
import classNames from 'classnames';
import * as styles from './ProductMessage.scss';

type ProductMessageProps = {
  productType: ProductsId;
  onProductMessagesSeen: (key: ProductsId, value: boolean) => void;
  productMessagesSeen: Record<string, boolean>;
};

export const ProductMessage: FC<ProductMessageProps> = ({
  productType,
  onProductMessagesSeen,
  productMessagesSeen,
}) => {
  const isProductMessageSeen = productMessagesSeen[productType];
  const productSelected = !!useSelector(selectedProductsSelector)?.[productType];
  const elementRef = useRef<HTMLDivElement>(null);

  const messagesKey = {
    wheelchair: 'search_mask.wheelchair_extra_info_message',
  };

  const { message } = useTranslations({
    message: messagesKey?.[productType] || '',
  });

  const handleSetProductMessageSeen = () => {
    onProductMessagesSeen(productType, true);
  };

  useScrollToElement({
    element: elementRef,
    active: productSelected && !!messagesKey?.[productType],
    elementSeen: !!isProductMessageSeen,
    onScrollToElement: handleSetProductMessageSeen,
  });

  if (!messagesKey?.[productType]) return null;

  return (
    <div
      className={classNames(styles.messagePopup, {
        [styles.hidden]: !productSelected,
      })}
      aria-live="polite"
      ref={elementRef}
    >
      <div dangerouslySetInnerHTML={{ __html: message() }} />
    </div>
  );
};

export default ProductMessage;
