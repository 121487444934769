import React, { FC } from 'react';
import classNames from 'classnames';
import * as styles from './DebugSwitch.scss';

type DebugSwitchProps = {
  selected?: string;
  options: Array<{ value: string; label: string }>;
  onChange: (x: { value: any; label: string }) => void;
};

export const DebugSwitch: FC<DebugSwitchProps> = ({ selected = null, options, onChange }) => (
  <div className={styles.debugSwitch}>
    {options.map((option) => (
      <button
        tabIndex={-1}
        key={option.value}
        className={classNames(styles.debugSwitchButton, {
          [styles.debugSwitchButtonSelected]: selected === option.value,
        })}
        onClick={() => onChange(option)}
      >
        {option.label}
      </button>
    ))}
  </div>
);
