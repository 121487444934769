import React, { FC } from 'react';
import { getTestingAttributes } from 'app/functions';
import { elements } from 'app/tests/elements';
import cn from 'classnames';
import { connect } from 'react-redux';
import { LazyComponent } from 'app/containers/LazyComponent';
import { IconPinSolid } from 'app/icons';
import { InputFallback } from 'app/components/ui/InputFallback';
import { arrivalCitySelector } from 'app/store/selectors';
import { TripLocationSwitcher } from 'app/components/ui/TripLocationSwitcher';
import * as styles from './TripLocationWrapper.scss';
import { retryCall } from 'app/api';
import { SearchMaskMountOptions } from '@webc/meeseeks-ui-sdk';
import { StoreState } from 'app/models/types';
import { useTranslations } from 'app/hooks/useTranslations';
import { CityDetailsResult, PartialSearchMaskLocation } from 'app/models/types/store/location';

const TripLocation = React.lazy(() => retryCall(() => import('app/components/tripLocation')));

type DumbTripLocationWrapperProps = {
  selectedArrival: CityDetailsResult | PartialSearchMaskLocation;
  variant: SearchMaskMountOptions['variant'];
};

const DumbTripLocationWrapper: FC<DumbTripLocationWrapperProps> = ({
  selectedArrival = { name: '' },
  variant,
}) => {
  const { translationGetFromLabel, translationGetToLabel, getTranslationLoading } = useTranslations(
    {
      translationGetFromLabel: 'from',
      translationGetToLabel: 'to',
      getTranslationLoading: 'search_mask.loading',
    },
  );

  return (
    <div
      className={cn(styles.locationWrapper, {
        [styles.compact]: variant === 'compact',
      })}
    >
      <div
        className={styles.locationWrapperDeparture}
        {...getTestingAttributes(elements.DEPARTURE_CITY_INPUT_FIELD)}
      >
        <LazyComponent
          fallback={
            <InputFallback
              label={translationGetFromLabel() || '...'}
              icon={IconPinSolid}
              loadingMessage={getTranslationLoading()}
            />
          }
        >
          <TripLocation direction="from" />
        </LazyComponent>
      </div>

      <TripLocationSwitcher disabled={!selectedArrival} />

      <div
        className={styles.locationWrapperArrival}
        {...getTestingAttributes(elements.ARRIVAL_CITY_INPUT_FIELD)}
      >
        <LazyComponent
          fallback={
            <InputFallback
              label={translationGetToLabel() || '...'}
              icon={IconPinSolid}
              loadingMessage={getTranslationLoading()}
            />
          }
        >
          <TripLocation direction="to" />
        </LazyComponent>
      </div>
    </div>
  );
};
const mapStateToProps = (state: StoreState) => ({
  selectedArrival: arrivalCitySelector(state),
});

export const TripLocationWrapper = connect(mapStateToProps)(DumbTripLocationWrapper);
