import { Continent } from '@webc/meeseeks-ui-sdk';
import { AutocompleteResult } from 'app/models/types/store/location';

export const groupResultsByContinent = (
  results: AutocompleteResult[],
  continentOrder: Continent[],
) => {
  const allContinents: Continent[] = ['africa', 'asia', 'europe', 'north_america', 'south_america'];
  const order = [...new Set([...continentOrder, ...allContinents])];

  const grouped = results.reduce<{ [continent: string]: AutocompleteResult[] }>(
    (objectsByKeyValue, result) => {
      const { continent } = result;

      return {
        ...objectsByKeyValue,
        [continent]: (objectsByKeyValue[continent] || []).concat(result),
      };
    },
    {},
  );
  // if no explicit continent order, persist order from autocomplete API
  if (continentOrder.length === 0) {
    return Object.keys(grouped).reduce<Map<string, AutocompleteResult[]>>(
      (map, key) => map.set(key, grouped[key]),
      new Map(),
    );
  }
  return order.reduce<Map<string, AutocompleteResult[]>>((map, key) => {
    if (grouped[key]) {
      map.set(key, grouped[key]);
    }
    return map;
  }, new Map());
};
