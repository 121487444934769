import React, { FC } from 'react';
import { WithOptions } from 'app/containers/WithOptions';
import { variants } from 'app/options';
import locales from 'app/config/locales.json';
import languageNames from 'app/config/files/languageNames.json';
import { DebugDropdown } from 'app/components/debug/DebugDropdown';
import { DebugSwitch } from 'app/components/debug/DebugSwitch';
import * as styles from './Debug.scss';
import { SearchMaskMountOptions } from '@webc/meeseeks-ui-sdk';

const getLanguageObject = (locale: string) => ({
  value: locale,
  label: languageNames[locale] || locale,
});

type DumbDebugProps = {
  options: SearchMaskMountOptions;
  updateOptions: (x: Partial<SearchMaskMountOptions>) => void;
};

export const DumbDebug: FC<DumbDebugProps> = ({ options, updateOptions }) => (
  <div className={styles.debugWrapper}>
    <div className={styles.debug}>
      <div className={`search-mask__language-chooser ${styles.debugSection}`}>
        <span className={styles.debugSectionLabel}>Locale</span>
        <DebugDropdown
          selected={getLanguageObject(options.locale)}
          options={locales.map(getLanguageObject)}
          onChange={(x: { value: string; label: string }) => {
            updateOptions({
              locale: x.value,
            });
          }}
        />
      </div>
      <div className={styles.debugSection}>
        <span className={`search-mask__variant-chooser ${styles.debugSectionLabel}`}>Layout</span>

        <DebugSwitch
          options={variants}
          selected={options.variant}
          onChange={(variant) =>
            updateOptions({
              variant: variant.value,
            })
          }
        />
      </div>
      <div className={styles.debugSection}>
        <span className={styles.debugSectionLabel}>FlixTrain</span>

        <DebugSwitch
          options={[
            {
              value: 'off',
              label: 'Off',
            },
            {
              value: 'on',
              label: 'On',
            },
          ]}
          selected={options.flixTrain ? 'on' : 'off'}
          onChange={(action) =>
            updateOptions({
              flixTrain: action.value === 'on',
            })
          }
        />
      </div>
      <div className={styles.debugSection}>
        <span className={styles.debugSectionLabel}>Theme</span>

        <DebugSwitch
          options={[
            {
              value: 'flix',
              label: 'flix',
            },
            {
              value: 'kamil',
              label: 'kk',
            },
            {
              value: 'neptune',
              label: 'gh',
            },
          ]}
          selected={options.theme}
          onChange={(action) =>
            updateOptions({
              theme: action.value,
            })
          }
        />
      </div>
    </div>
  </div>
);

// @ts-expect-error ignore
export const Debug = WithOptions(DumbDebug);
