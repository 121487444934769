import React from 'react';
import { useTranslations } from 'app/hooks/useTranslations';
import { Box } from '@flixbus/honeycomb-react';
import * as styles from 'app/components/product/ProductPopupContent/ProductPopupContent.scss';

export const GroupTripMessage = ({
  areGroupTripsAvailable,
  passengerCount,
  infoMessageRef,
}: {
  areGroupTripsAvailable: boolean;
  passengerCount: number;
  infoMessageRef: React.RefObject<HTMLDivElement>;
}) => {
  const { getGroupTripsMessage } = useTranslations({
    getGroupTripsMessage: 'search_mask.info_message.group_trips',
  });
  if (areGroupTripsAvailable && passengerCount >= 5 && getGroupTripsMessage()) {
    return (
      <div ref={infoMessageRef}>
        <Box extraClasses={styles.infoMessage} appearance="neutral" small aria-live="polite">
          <div dangerouslySetInnerHTML={{ __html: getGroupTripsMessage() }} />
        </Box>
      </div>
    );
  }

  return null;
};
