import React, { FC } from 'react';
import { getTestingAttributes } from 'app/functions';
import { elements } from 'app/tests/elements';
import { retryCall } from 'app/api';
import { connect, useSelector } from 'react-redux';
import { LazyComponent } from 'app/containers/LazyComponent';
import { InputFallback } from 'app/components/ui/InputFallback';
import { localeSelector, variantSelector } from 'app/store/selectors';
import { getPolishDiscountLink } from 'app/functions/product';
import * as styles from './TripProductPicker.scss';
import { StoreState } from 'app/models/types';
import { useTranslations } from 'app/hooks/useTranslations';

const ProductPopup = React.lazy(() =>
  retryCall(() =>
    import('app/containers/ProductPopup').then((e) => ({
      default: e.ProductPopup,
    })),
  ),
);

export const TripProductPicker: FC = () => {
  const locale = useSelector(localeSelector);

  const { getPassengersLabel, getTranslationLoading } = useTranslations({
    getPassengersLabel: 'search_bundle.search_widget.passengers',
    getTranslationLoading: 'search_mask.loading',
  });

  return (
    <div {...getTestingAttributes(elements.PRODUCTS_INPUT_FIELD)} className={styles.productPicker}>
      <LazyComponent
        fallback={
          <InputFallback
            label={getPassengersLabel() || '...'}
            loadingMessage={`${getTranslationLoading()}`}
          />
        }
      >
        <ProductPopup
          label={getPassengersLabel()}
          extendedProductsInformationLink={getPolishDiscountLink(locale)}
          popupTitle={getPassengersLabel()}
        />
      </LazyComponent>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  variant: variantSelector(state),
});

export default connect(mapStateToProps, {})(TripProductPicker);
