import React from 'react';
import { InputFallback } from 'app/components/ui/InputFallback';
import * as stylesFallback from 'app/components/ui/InputFallback/InputFallback.scss';
import * as styles from './DatePickerFallback.scss';
import { IconCalendarSolid } from 'app/icons';
import { useTranslations } from 'app/hooks/useTranslations';

export const DatePickerFallback = () => {
  const { getTranslationDepartureDateLabel, getTranslationLoading } = useTranslations({
    getTranslationDepartureDateLabel: 'depart',
    getTranslationLoading: 'search_mask.loading',
  });

  return (
    <div className={`${styles.datePickerFallback} ${stylesFallback.fallback}`}>
      <div className={styles.dateInput}>
        <InputFallback
          label={getTranslationDepartureDateLabel() || '...'}
          icon={IconCalendarSolid}
          loadingMessage={`${getTranslationLoading()}`}
        />
      </div>
    </div>
  );
};
