import React, { FC } from 'react';
import classNames from 'classnames';
import { Button } from '@flixbus/honeycomb-react';
import { Icon, IconSwitch } from 'app/icons';
import * as styles from './TripLocationSwitcher.scss';
import { a11yLabelsKeys } from 'app/providers/a11yLabels';
import { useTranslations } from 'app/hooks/useTranslations';
import { useLocationSwitcher } from './useLocationSwitcher';

type TripLocationSwitcherProps = {
  disabled: boolean;
};

export const TripLocationSwitcher: FC<TripLocationSwitcherProps> = ({ disabled }) => {
  const { getAriaLabel } = useTranslations({
    getAriaLabel: a11yLabelsKeys.locationInputs.locationSwitcher,
  });

  const { handleSwitchLocations, rotate } = useLocationSwitcher();

  return (
    <Button
      aria-label={getAriaLabel()}
      onClick={handleSwitchLocations}
      extraClasses={classNames(styles.locationSwitcher, {
        [styles.rotate]: rotate,
      })}
      disabled={disabled}
      display="square"
      size="sm"
    >
      <Icon InlineIcon={IconSwitch} appearance="primary" />
    </Button>
  );
};
