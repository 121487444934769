import * as styles from './Autocomplete.scss';
import cn from 'classnames';
import Highlighter from 'react-highlight-words';
import React from 'react';
import { ResultsType as ResultsPropType } from 'app/hooks/useResults';
import { AutocompleteOptionType } from '@flixbus/honeycomb-react/src/components/autocomplete/autocomplete-options/types';
import { AutocompleteResult, AutocompleteResultStation } from 'app/models/types/store/location';
import { Icon, IconTrain, IconBus } from 'app/icons';
import { useTranslations } from 'app/hooks/useTranslations';
import { a11yLabelsKeys } from 'app/providers/a11yLabels';
import { MapLink } from 'app/components/tripLocation/MapLink/MapLink';
import { useSelector } from 'react-redux';
import { flixTrainSelector } from 'app/store/selectors';

type OptionPropTypes = {
  value: string;
  resultType: ResultsPropType;
  item: AutocompleteOptionType;
  citiesById: Record<
    string,
    AutocompleteResult & {
      positionOnList: number;
      station: AutocompleteResultStation;
      filteredStations: AutocompleteResultStation[];
    }
  >;
  direction: 'from' | 'to';
};
export const Option = ({ value, resultType, item, citiesById, direction }: OptionPropTypes) => {
  const {
    getTrainStationLabel,
    getTranslation,
    getAllStopsDepartureLabel,
    getAllStopsArrivalLabel,
  } = useTranslations(
    {
      getAllStopsDepartureLabel: 'search_mask.label.all_stops.departure',
      getAllStopsArrivalLabel: 'search_mask.label.all_stops.arrival',
      getTrainStationLabel: a11yLabelsKeys.trainStation,
    },
    true,
  );
  const isFlixTrainDomain = useSelector(flixTrainSelector);

  if (item.key === 'mapItem') {
    return (
      <MapLink
        title={getTranslation(
          `search_mask.label.${direction === 'from' ? 'where_to_start' : 'where_to_go'}`,
        )}
        text={getTranslation('search_mask.label.explore_the_map')}
      />
    );
  }

  const cityData = item.key ? citiesById[item.key] : undefined;

  const isTrain = cityData?.station?.is_train;

  return (
    <>
      {cityData && (
        <div
          className={cn(styles.optionWrapper, {
            [styles.station]: cityData.isStation,
          })}
        >
          <div className={cn('notranslate', styles.option)}>
            <div className={styles.cityName}>
              {cityData.isStation && (
                <Icon
                  appearance="primary"
                  extraClasses={styles.pinIcon}
                  aria-hidden={!isTrain}
                  aria-label={isTrain ? getTrainStationLabel() : ''}
                  InlineIcon={isTrain ? IconTrain : IconBus}
                />
              )}
              <Highlighter searchWords={[value]} textToHighlight={item.title} autoEscape />
              {!!cityData.filteredStations?.length &&
                (cityData.filteredStations?.length > 1 || isFlixTrainDomain) &&
                resultType === 'autocomplete' && (
                  <span className={styles.allStopsLabel}>
                    (
                    {direction === 'from' ? getAllStopsDepartureLabel() : getAllStopsArrivalLabel()}
                    )
                  </span>
                )}
            </div>
            <div className={styles.additionalInfo}>{item.subtitle}</div>
          </div>
        </div>
      )}
    </>
  );
};
