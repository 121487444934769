import React, { FC } from 'react';
import { elements } from 'app/tests/elements';
import { getTestingAttributes } from 'app/functions';
import { Fieldset, Radio } from '@flixbus/honeycomb-react';
import cn from 'classnames';

import * as styles from './TripModePicker.scss';
import { useTranslations } from 'app/hooks/useTranslations';

export type TripModePickerProps = {
  isRoundTrip: boolean;
  onClickOneWayToggle: () => void;
  onClickRoundTripToggle: () => void;
};

const TripModePicker: FC<TripModePickerProps> = ({
  onClickOneWayToggle,
  onClickRoundTripToggle,
  isRoundTrip,
}) => {
  const { getOneWayTranslation, getRoundTripTranslation } = useTranslations({
    getOneWayTranslation: 'search_mask.label.one_way',
    getRoundTripTranslation: 'search_mask.label.round_trip',
  });

  return (
    <Fieldset extraClasses={cn(styles.fieldset, styles.multiLine)}>
      <Radio
        {...getTestingAttributes(elements.ONE_WAY_TRIP_MODE)}
        aria-label={getOneWayTranslation()}
        label={getOneWayTranslation() || '…'}
        id="search-mask-trip-mode-oneway-toggle"
        name="search-mask-trip-mode"
        value={0}
        onChange={onClickOneWayToggle}
        checked={!isRoundTrip}
      />
      <Radio
        {...getTestingAttributes(elements.ROUND_TRIP_MODE)}
        aria-label={getRoundTripTranslation()}
        label={getRoundTripTranslation() || '…'}
        id="search-mask-trip-mode-roundtrip-toggle"
        name="search-mask-trip-mode"
        value={1}
        onChange={onClickRoundTripToggle}
        checked={isRoundTrip}
      />
    </Fieldset>
  );
};

export { TripModePicker };
