import { Input } from '@flixbus/honeycomb-react';
import { Icon, IconComponent, IconProps } from 'app/icons';
import React, { FC } from 'react';
import * as styles from './InputFallback.scss';

type InputFallbackProps = {
  label: string;
  loadingMessage: string;
  icon?: IconComponent;
  iconProps?: Partial<IconProps>;
};

export const InputFallback: FC<InputFallbackProps> = ({
  label,
  icon,
  loadingMessage,
  iconProps,
}) => (
  <div className={styles.fallback}>
    <Input
      aria-label="fallback"
      extraClasses={styles.input}
      id="inputFallback"
      label={label}
      iconLeft={
        icon && <Icon appearance="primary" InlineIcon={icon} {...iconProps} aria-label="" />
      }
      placeholder={`${loadingMessage} \u2026`}
    />
  </div>
);
