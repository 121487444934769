import { ResultsType as ResultsPropType } from 'app/hooks/useResults';
import * as styles from './Autocomplete.scss';
import { Icon, IconCitySolid, IconTimeSolid } from 'app/icons';
import React from 'react';

export const GroupTitle = ({ type, title }: { type: ResultsPropType; title: string }) => (
  <span role="presentation" className={styles.continent}>
    {type !== 'autocomplete' && (
      <span aria-hidden>
        <Icon
          appearance="primary"
          InlineIcon={type === 'recent' ? IconTimeSolid : IconCitySolid}
          extraClasses={styles.titleIcon}
        />
      </span>
    )}
    {title}
  </span>
);
